//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future

.red_border {
  border: 2px solid #c00;
}


.header_right{ display: flex;align-items: center;justify-content: space-between;width: 100%;
}
.layout-topbar .layout-topbar-menu{
  margin: 0;
}
.title_head{
  color: var(--surface-900);
  font-size: 1.5rem;
  font-weight: bolder;
    color: #543076;
}

.layout-topbar-logo {
  font-weight: bolder !important;
    color: #c33834 !important;
}